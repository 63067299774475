<template>
  <main class="InvoicesView">
    <div class="container">
      <header class="mb-3 d-flex">
        <h1 class="m-0">Invoices</h1>
      </header>

      <AppLoading v-if="loading" :spaced="false" />

      <AppEmptyList v-if="!loading && !invoices.length" />

      <section class="InvoicesView__list mb-5" v-if="!loading && invoices.length">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="InvoicesView__table table">
                <colgroup>
                  <col style="width: 125px">
                  <col>
                  <col style="width: 150px">
                  <col style="width: 150px">
                  <col style="width: 150px">
                  <col style="width: 50px">
                </colgroup>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Invoice #</th>
                    <th class="text-end">Perf. fees</th>
                    <th class="text-end">Quarter. fees</th>
                    <th class="text-end">Due</th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="invoice in invoices" :key="invoice.id" @click.stop="go(invoice.id)">
                    <td class="text-primary">
                      {{ formatDate(invoice.date, 'MMMM yyyy') }}
                    </td>
                    <td>{{ invoice.number }}</td>
                    <td class="text-end">
                      {{ formatDecimal(invoice.settings.performance_fee) }}%
                    </td>
                    <td class="text-end">
                      {{ formatDecimal(invoice.settings.quaterly_fee) }}%
                    </td>
                    <td class="text-end">
                      <strong class="text-primary">
                        ${{ formatDecimal(invoice.due.performance_fee + invoice.due.quaterly_fee) }}
                      </strong>
                    </td>
                    <td class="text-end">
                      <button title="Open invoice" class="btn btn-link" @click="go(invoice.id)">
                        <i class="bi bi-eye" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading'
  import AppEmptyList from '@/components/AppEmptyList'

  import invoiceService from '@/services/invoices.service'

  import { formatDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { mapState } from 'vuex'

  export default {
    components: {
      AppEmptyList,
      AppLoading
    },

    data() {
      return {
        loading: true,
        invoices: null,
        exchange: null
      }
    },

    computed: {
      ...mapState('auth', [
        'authenticated',
        'impersonated'
      ])
    },

    mounted() {
      this.load()
    },

    methods: {
      formatDate,
      formatDecimal,

      async load() {
        this.loading = true
        this.invoices = await invoiceService.fetch()
        this.loading = false
      },

      go(id) {
        this.$router.push({ name: 'invoiceDetails', params: { id } })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoicesView__table
    tr
      vertical-align: middle
      cursor: pointer

    th, td
      white-space: nowrap

  .InvoicesView__table__logo
    width: 32px
    height: 32px
</style>
